import React from 'react'
import { graphql } from 'gatsby'
import Image from 'gatsby-image'
import { formatDistance } from 'date-fns'

import Layout from '../components/layout'
import SEO from '../components/seo'

import style from './about-me.module.scss'

const movingDate = new Date('2018-01-03')
const today = new Date()

export const pageQuery = graphql`
  query AboutMe {
    site {
      siteMetadata {
        keywords,
        author
      }
    }
    avatar: file(absolutePath: { regex: "/profile.jpg/" }) {
      childImageSharp {
        fixed(width: 300, height: 300, quality: 90) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`

const AboutMe = ({ data, location }) => {
  const { keywords, author } = data.site.siteMetadata;

  return (
    <Layout location={location} title="About Me" showMenu goHome={false} className={style.root}>
      <SEO title="About Me" keywords={keywords}/>
      <Image
        fixed={data.avatar.childImageSharp.fixed}
        alt={author}
        className={style.img}
        imgStyle={{ transform: `scale(1.15)`, }}
      />
      <p>
        My name is <strong>{author}</strong>.
        I'm a self-taught software developer and currently Associate Architect <span aria-label="at">@</span> Thomson Reuters.
      </p>
      <p>
        I was born in <a href="https://goo.gl/maps/teJyYN4YDPF2" target="_blank" rel="noopener noreferrer">Luján, Buenos Aires, Argentina</a> and when I finished high school I moved to <a href="https://goo.gl/maps/eMojRW1crPC2" target="_blank" rel="noopener noreferrer">City of Buenos Aires, Argentina</a> to start working and expanding my knowledge but {formatDistance(movingDate, today)} ago I moved to <a href="https://goo.gl/maps/6vkaAKZ5YWN2" target="_blank" rel="noopener noreferrer">Ann Arbor, Michigan, Unites States</a> as part of my career in <a href="https://www.thomsonreuters.com/" target="_blank" rel="noopener noreferrer">Thomson Reuters</a>.
      </p>
      <p>
        Apart from coding, in my free time I like cooking <i>(and eating of course)</i>, riding my bike in the nature <i>(when is not freezing)</i> and experimenting with robots, electronic, and domotics.
        Also, I like writing <i>(in this blog)</i> about my experiences in the tech industry and community.
      </p>
    </Layout>
  )
}

export default AboutMe